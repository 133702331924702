import { AttachmentMetadata, AttachmentMetadataResponse, MayBeNull, ProjectType } from '@wpp-open/core'

import { DevHubProductType, VersionType } from 'types/apps/devHubApps'
import { NoCodeAppType } from 'types/apps/noCodeApps'

export enum AppInstanceAssignmentType {
  Workspace = 'WORKSPACE',
  Project = 'PROJECT',
  Custom = 'CUSTOM',
}

export enum AppInstanceStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

// TODO: AppInstance typings should be refactored

interface CustomContextField {
  key: string
  value: string
}

export interface AppInstanceDevHubMetadata<Config extends MayBeNull<{}>, CustomContext extends CustomContextField[]> {
  id: string
  name: string
  shortDescription: string
  productType: DevHubProductType
  noCodeAppType: MayBeNull<NoCodeAppType>
  version: {
    id: string
    name: string
    description: MayBeNull<string>
    routePath: MayBeNull<string>
    appCodeName: MayBeNull<string>
    versionType: VersionType
    jsBundleUrl: MayBeNull<string>
    jsLibraryBundleType: MayBeNull<string>
    customContext: CustomContext
    deleted: MayBeNull<boolean>
    dataContext: MayBeNull<string[]>
    config: Config
    externalConfigId: MayBeNull<string>
  }
  ownerName: string
  ownerEmail: string
  logoMeta: MayBeNull<{
    original: AttachmentMetadata
    thumbnail: AttachmentMetadata
  }>
  markets: {
    region: string
    markets: {
      marketCode: string
      marketTitle: string
    }[]
  }[]
}

interface ProjectContext {
  appId: string
  phaseId: string
  projectId: string
  projectName: string
  projectType: ProjectType
  taskIsCompleted: boolean
  workspaceId: string
  miroOwner: string
}

type AppInstanceAssignmentContext<WorkspaceContext extends {}> =
  | {
      assignmentType: AppInstanceAssignmentType.Workspace
      context: WorkspaceContext
    }
  | {
      assignmentType: AppInstanceAssignmentType.Custom
      context: WorkspaceContext
    }
  | {
      assignmentType: AppInstanceAssignmentType.Project
      context: ProjectContext
    }

type AppInstanceShortBase<
  Context extends {},
  Config extends MayBeNull<{}>,
  CustomContext extends CustomContextField[] = CustomContextField[],
> = AppInstanceAssignmentContext<Context> & {
  id: string
  tenantId: string
  devhubAppId: string
  assignmentId: string
  devhubAppVersionId: string
  devhubMetadata: AppInstanceDevHubMetadata<Config, CustomContext>
  osRoute: MayBeNull<string>
  status: AppInstanceStatus
  position: number
  group: MayBeNull<{
    id: string
    name: string
    description: MayBeNull<string>
    order: MayBeNull<number>
  }>
  subgroup: MayBeNull<{
    id: string
    name: string
    description: MayBeNull<string>
    order: MayBeNull<number>
  }>
  flags: {
    visible: boolean
    accessRequestable: boolean
  }
  name: MayBeNull<string>
  description: MayBeNull<string>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
}

export type AppInstanceEmbeddedCodeShort = AppInstanceShortBase<
  {
    embeddedCode: string
  },
  {
    appType: NoCodeAppType.EmbeddedCode
    routePath: string
    embeddedCode: string
  }
>

export type AppInstanceEmbeddedLinkShort = AppInstanceShortBase<
  {
    url: string
  },
  {
    appType: NoCodeAppType.EmbeddedLink
    routePath: string
    url: string
  }
>

export type AppInstanceExternalLinkShort = AppInstanceShortBase<
  {
    url: string
  },
  {
    appType: NoCodeAppType.ExternalLink
    url: string
  }
>

export type AppInstancePageBuilderShort = AppInstanceShortBase<
  {},
  {
    appType: NoCodeAppType.PageBuilder
    routePath: string
  }
>

export type AppInstanceMiroShort = AppInstanceShortBase<
  {
    url?: string
  },
  {
    appType: NoCodeAppType.MiroBoard
    routePath: string
  }
>

export type AppInstanceCodeUploadShort = AppInstanceShortBase<
  {},
  {
    appType: NoCodeAppType.CodeUpload
    routePath: string
    homePageUrl: string
  }
>

export type AppInstanceNativeCustomContext = CustomContextField[]
export type AppInstanceNativeLegacyCustomContext = [
  { key: 'legacy'; value: 'true' },
  { key: 'windowLibraryName'; value: string },
  { key: 'containerId'; value: string },
]

export type AppInstanceNativeShort = AppInstanceShortBase<
  {},
  null,
  AppInstanceNativeCustomContext | AppInstanceNativeLegacyCustomContext
>

export type AppInstanceNoCodeShort =
  | AppInstanceEmbeddedCodeShort
  | AppInstanceEmbeddedLinkShort
  | AppInstanceExternalLinkShort
  | AppInstancePageBuilderShort
  | AppInstanceMiroShort
  | AppInstanceCodeUploadShort

export type AppInstanceShort = AppInstanceNoCodeShort | AppInstanceNativeShort

export type AppInstanceFull<T extends AppInstanceShort = AppInstanceShort> = T & {
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
}

export interface AppInstanceDefaultCreateDTO {
  devhubAppId: string
  assignmentId: string
  devhubAppVersionId: string
  name: MayBeNull<string>
  assignmentType: AppInstanceAssignmentType
  description: MayBeNull<string>
  groupId?: string
  subgroupId?: string
}

interface AppInstanceEmbeddedLinkCreateDTO extends AppInstanceDefaultCreateDTO {
  context: {
    url: string
  }
  osRoute: string
}

interface AppInstanceExternalLinkCreateDTO extends AppInstanceDefaultCreateDTO {
  context: {
    url: string
  }
}

interface AppInstancePageBuilderCreateDTO extends AppInstanceDefaultCreateDTO {
  osRoute: string
}

interface AppInstanceMiroCreateDTO extends AppInstanceDefaultCreateDTO {
  osRoute: string
}

interface AppInstanceEmbeddedCodeCreateDTO extends AppInstanceDefaultCreateDTO {
  context: {
    embeddedCode: string
  }
  osRoute: string
}

export type AppInstanceCreateDTO =
  | AppInstanceDefaultCreateDTO
  | AppInstanceEmbeddedCodeCreateDTO
  | AppInstanceMiroCreateDTO
  | AppInstanceEmbeddedLinkCreateDTO
  | AppInstanceExternalLinkCreateDTO
  | AppInstancePageBuilderCreateDTO

interface AppInstanceDefaultEditDTO {
  name: MayBeNull<string>
  groupId?: MayBeNull<string>
  subgroupId?: MayBeNull<string>
  flags: {
    visible: boolean
    accessRequestable: boolean
  }
}

interface AppInstanceEmbeddedLinkEditDTO extends AppInstanceDefaultEditDTO {
  context: {
    url: string
  }
  description: MayBeNull<string>
  osRoute: string
}

interface AppInstanceExternalLinkEditDTO extends AppInstanceDefaultEditDTO {
  context: {
    url: string
  }
  description: MayBeNull<string>
}

interface AppInstancePageBuilderEditDTO extends AppInstanceDefaultEditDTO {
  description: MayBeNull<string>
  osRoute: string
}

interface AppInstanceMiroEditDTO extends AppInstanceDefaultEditDTO {
  description: MayBeNull<string>
  osRoute: string
}

interface AppInstanceEmbeddedCodeEditDTO extends AppInstanceDefaultEditDTO {
  context: {
    embeddedCode: string
  }
  description: MayBeNull<string>
  osRoute: string
}

export interface AppInstanceWithLogoImagesOnlyDTO {
  logoOriginal?: MayBeNull<AttachmentMetadata>
  logoThumbnail?: MayBeNull<AttachmentMetadata>
}

type AppInstanceLogoEditDTO = AppInstanceDefaultEditDTO & AppInstanceWithLogoImagesOnlyDTO

export type AppInstanceEditDTO =
  | AppInstanceEmbeddedCodeEditDTO
  | AppInstanceMiroEditDTO
  | AppInstancePageBuilderEditDTO
  | AppInstanceExternalLinkEditDTO
  | AppInstanceEmbeddedLinkEditDTO
  | AppInstanceLogoEditDTO
  | AppInstanceDefaultEditDTO
