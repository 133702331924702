import { AttachmentMetadata, MayBeNull } from '@wpp-open/core'

import { NoCodeAppShort, NoCodeAppType, NoCodeAppVersion } from 'types/apps/noCodeApps'

export enum DevHubProductType {
  NATIVE_APPLICATION = 'NATIVE_APPLICATION',
  NO_CODE_APPLICATION = 'NO_CODE_APPLICATION',
}

export enum DevHubFilteringType {
  ALL = 'ALL',
  NATIVE = 'NATIVE',
  CODE_UPLOAD = 'CODE_UPLOAD',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  EMBEDDED_CODE = 'EMBEDDED_CODE',
  EMBEDDED_LINK = 'EMBEDDED_LINK',
  MIRO_BOARD = 'MIRO_BOARD',
  PAGE_BUILDER = 'PAGE_BUILDER',
}

export type DevHubAppsType = MayBeNull<NoCodeAppType | DevHubProductType.NATIVE_APPLICATION>

export type DevHubAttachmentMetadata = AttachmentMetadata & {
  downloadUrl?: string
}

export interface DevHubNativeApp {
  id: string
  ownerName: string
  ownerEmail: string
  shortDescription: string
  logoMeta: MayBeNull<{
    original: DevHubAttachmentMetadata
    thumbnail: DevHubAttachmentMetadata
  }>
  markets: {
    region: string
    markets: {
      marketCode: string
      marketTitle: string
    }[]
  }[]
  name: string
  tenantId: string
  logoUrl?: string
}
export enum VersionType {
  PRODUCTION = 'PRODUCTION',
  DEMO = 'DEMO',
  DEVELOPMENT = 'DEVELOPMENT',
}
export interface DevHubAppVersionShort {
  id: string
  name: string
  description: MayBeNull<string>
  routePath: MayBeNull<string>
  dataContext: MayBeNull<string[]>
  versionType: VersionType
}

interface DevHubAppShortBase {
  id: string
  ownerName: string
  ownerEmail: string
  shortDescription: string
  name: string
  tenantId: string
  logoMeta: MayBeNull<{
    original: DevHubAttachmentMetadata
    thumbnail: DevHubAttachmentMetadata
  }>
  markets: {
    region: string
    markets: {
      marketCode: string
      marketTitle: string
    }[]
  }[]
  versions: DevHubAppVersionShort[]
}

export interface DevHubNativeAppShort extends DevHubAppShortBase {
  productType: DevHubProductType.NATIVE_APPLICATION
  noCodeAppType: null
}

export interface DevHubNoCodeAppBaseShort extends DevHubAppShortBase {
  productType: DevHubProductType.NO_CODE_APPLICATION
  noCodeAppType: NoCodeAppType
}

export type DevHubAppShort = DevHubNativeAppShort | NoCodeAppShort

export type DevHubAppVersion = DevHubAppVersionShort | NoCodeAppVersion
