import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useProjectNavigationApi } from 'api/projects/queries/useProjectNavigationApi'
import { is403Error } from 'api/utils'
import { AppInstancesDefaults } from 'constants/appInstances'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { OsStateError } from 'providers/osState/sideEffects/OsStateError'
import { getAppInstanceAppData } from 'providers/osState/utils/appData'
import { isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import {
  filterAppInstanceWorkspaceId,
  getInvalidResolvedAppData,
  getLoadingResolvedAppData,
  isValidAppInstanceWorkspace,
} from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppInstanceFull } from 'types/apps/appInstances'
import { ProjectCanvasApplicationShort, ProjectNavigation, ProjectPhaseShort } from 'types/projects/projectNavigation'
import { formatAppCustomConfig, RoutedAppInstanceApp } from 'utils/appInstances'
import {
  isProjectActivityData,
  isProjectAppActive,
  isProjectNavigationFluid,
  isProjectNavigationLinear,
} from 'utils/projects'

interface Params {
  appInstance: AppInstanceFull
  app: RoutedAppInstanceApp
  currentBaseUrl: string
}

export const useProjectAppInstance = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree } = useCurrentTenantData()

  const isEnabled = !!params
  const projectId = isEnabled ? params.appInstance.assignmentId : UnsetValue
  const appCustomConfig = useMemo(
    () => (isEnabled ? formatAppCustomConfig(params.appInstance) : UnsetValue),
    [isEnabled, params?.appInstance],
  )

  // Load Project data if enabled
  const {
    isLoading,
    data: projectNavigation,
    error: projectNavigationError,
  } = useProjectNavigationApi({
    params: {
      projectId: isValueSet(projectId) ? projectId : '',
    },
    gcTime: AppInstancesDefaults.ProjectsGCTime,
    enabled: isEnabled,
  })

  let result: MayBeNull<ResolvedAppData> = null

  // Process loading state and errors
  if (isEnabled) {
    if (isLoading) {
      result = getLoadingResolvedAppData()
    } else if (projectNavigationError) {
      const isProjectDataForbiddenError = is403Error(projectNavigationError)

      result = getLoadingResolvedAppData({
        sideEffectNode: <OsStateError isForbidden={isProjectDataForbiddenError} />,
      })
    }
  }

  // Project data is ready to be processed
  if (isEnabled && !result && projectNavigation && isValueSet(appCustomConfig)) {
    const { appInstance, app, currentBaseUrl } = params
    const { project, canvas: projectCanvas } = projectNavigation
    const workspaceAzId = project.contextWorkspace
    const additionalContext = appInstance.context

    const filteredWorkspaceAzId = filterAppInstanceWorkspaceId({
      workspaceAzId,
      navigationTree,
    })
    const isWorkspaceValid = isValidAppInstanceWorkspace({
      workspaceAzId: filteredWorkspaceAzId,
      navigationTree,
      app,
    })

    const {
      isValid: isProjectDataValid,
      projectPhase,
      projectItem,
    } = getProjectData({
      app,
      projectNavigation,
    })

    if (isWorkspaceValid && isProjectDataValid) {
      result = {
        appData: getAppInstanceAppData({
          app,
          currentBaseUrl,
          activeWorkspaceId: filteredWorkspaceAzId,
          appInstance,
          project,
          projectCanvas,
          projectPhase,
          projectItem,
          appCustomConfig,
          additionalContext,
          isCustomNavigation: false,
        }),
        sideEffectNode: null,
      }
    } else {
      if (!isWorkspaceValid) {
        console.error(`Invalid workspace level for app '${app.name}'`)
      }
      if (!isProjectDataValid) {
        console.error(`Invalid project data for app '${app.name}'`)
      }

      result = getInvalidResolvedAppData()
    }
  }

  return isEnabled ? result || getInvalidResolvedAppData() : null
}

const getProjectData = ({
  app,
  projectNavigation,
}: {
  app: RoutedAppInstanceApp
  projectNavigation: ProjectNavigation
}): {
  isValid: boolean
  projectPhase: MayBeNull<ProjectPhaseShort>
  projectItem: MayBeNull<ProjectCanvasApplicationShort>
} => {
  const getResult = (
    isValid: boolean,
    projectPhase: MayBeNull<ProjectPhaseShort> = null,
    projectItem: MayBeNull<ProjectCanvasApplicationShort> = null,
  ) => ({
    isValid,
    projectPhase,
    projectItem,
  })

  const projectCanvasLinear = isProjectNavigationLinear(projectNavigation) ? projectNavigation.canvas : null
  const projectCanvasFluid = isProjectNavigationFluid(projectNavigation) ? projectNavigation.canvas : null

  if (projectCanvasLinear) {
    for (const projectPhase of projectCanvasLinear.phases) {
      for (const phaseItem of projectPhase.phaseItems) {
        if (isProjectActivityData(phaseItem.item)) {
          for (const activityChildItem of phaseItem.item.items) {
            if (isProjectAppActive(activityChildItem.application, app)) {
              return getResult(true, projectPhase, activityChildItem.application)
            }
          }
        } else if (isProjectAppActive(phaseItem.item, app)) {
          return getResult(true, projectPhase, phaseItem.item)
        }
      }
    }

    return getResult(false)
  } else if (projectCanvasFluid) {
    for (const phaseItemData of projectCanvasFluid.items) {
      if (isProjectActivityData(phaseItemData)) {
        for (const activityChildItem of phaseItemData.items) {
          if (isProjectAppActive(activityChildItem.application, app)) {
            return getResult(true, null, activityChildItem.application)
          }
        }
      } else if (isProjectAppActive(phaseItemData, app)) {
        return getResult(true, null, phaseItemData)
      }
    }

    return getResult(false)
  }

  return getResult(false)
}
