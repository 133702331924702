import {
  DefaultHierarchyLevelType,
  HierarchyCustomNodeType,
  HierarchyNodeType,
  MayBeNull,
  NavigationTreeMapping,
} from '@wpp-open/core'

import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import {
  AppInstanceAssignmentType,
  AppInstanceCodeUploadShort,
  AppInstanceEmbeddedCodeShort,
  AppInstanceEmbeddedLinkShort,
  AppInstanceExternalLinkShort,
  AppInstanceFull,
  AppInstanceMiroShort,
  AppInstanceNativeLegacyCustomContext,
  AppInstanceNativeShort,
  AppInstancePageBuilderShort,
  AppInstanceShort,
} from 'types/apps/appInstances'
import { DevHubProductType } from 'types/apps/devHubApps'
import { MicroAppLibraryType, LegacyMicroAppConfig, MicroAppType, NativeMicroAppConfig } from 'types/apps/microApps'
import { NoCodeApp, NoCodeAppExternalLink, NoCodeAppType } from 'types/apps/noCodeApps'
import { isRequiredHierarchyLevel } from 'utils/workspace'

export type AppInstanceApp = NativeMicroAppConfig | LegacyMicroAppConfig | NoCodeApp
export type RoutedAppInstanceApp = Exclude<AppInstanceApp, NoCodeAppExternalLink>

// This helper enforces an exhaustive check for isNoCodeAppInstanceApp()
const filterNoCodeAppInstanceApp = (app: AppInstanceApp): MayBeNull<NoCodeApp> =>
  app.type !== MicroAppType.Native && app.type !== MicroAppType.Legacy ? app : null

export const getAppInstanceName = (appInstance: AppInstanceFull | AppInstanceShort) =>
  appInstance.name ||
  (appInstance.devhubMetadata.productType === DevHubProductType.NATIVE_APPLICATION
    ? appInstance.devhubMetadata.version.name
    : appInstance.devhubMetadata.name)

export const formatAppCustomConfig = (appInstance: AppInstanceFull | AppInstanceShort) => {
  const customContext = appInstance.devhubMetadata.version.customContext || []

  return Object.fromEntries(customContext.map(({ key, value }) => [key, value]))
}

export const isNoCodeAppInstanceApp = (app: AppInstanceApp): app is NoCodeApp => !!filterNoCodeAppInstanceApp(app)

export const mapAppInstanceToApp = (appInstance: AppInstanceFull | AppInstanceShort): AppInstanceApp => {
  const stableId = appInstance.id
  const name = getAppInstanceName(appInstance)
  const noCodeAppType = appInstance.devhubMetadata.version.config?.appType

  // TODO: Needs refactoring. With proper appInstance typings these "as" casts should be removed

  if (noCodeAppType === NoCodeAppType.ExternalLink) {
    const externalLinkAppInstance = appInstance as AppInstanceExternalLinkShort
    let url = externalLinkAppInstance.devhubMetadata.version.config.url

    if (
      externalLinkAppInstance.assignmentType === AppInstanceAssignmentType.Workspace &&
      externalLinkAppInstance.context.url
    ) {
      url = externalLinkAppInstance.context.url
    }

    return {
      type: NoCodeAppType.ExternalLink,
      stableId,
      name,
      url,
    }
  }

  const routedAppInstance = appInstance as Exclude<AppInstanceShort, AppInstanceExternalLinkShort>
  const osRoute = routedAppInstance.osRoute || routedAppInstance.devhubMetadata.version.routePath!

  if (!noCodeAppType) {
    const nativeAppInstance = routedAppInstance as AppInstanceNativeShort

    const libraryType =
      (nativeAppInstance.devhubMetadata.version.jsLibraryBundleType as MicroAppLibraryType) ||
      MicroAppLibraryType.SystemJS
    const bundleUrl = nativeAppInstance.devhubMetadata.version.jsBundleUrl || ''
    const requiredHierarchy: HierarchyNodeType[] = (nativeAppInstance.devhubMetadata.version.dataContext || []).filter(
      (value: string): value is HierarchyNodeType =>
        value === HierarchyCustomNodeType ||
        Object.values(DefaultHierarchyLevelType).includes(value as DefaultHierarchyLevelType),
    )

    const customContext = nativeAppInstance.devhubMetadata.version.customContext
    const isLegacy = !!customContext.find(item => item.key === 'legacy' && item.value === 'true')
    const windowLibraryName = customContext.find(item => item.key === 'windowLibraryName')?.value || null

    if (isLegacy) {
      const legacyCustomContext = customContext as AppInstanceNativeLegacyCustomContext
      const containerId = legacyCustomContext.find(item => item.key === 'containerId')?.value || ''

      return {
        type: MicroAppType.Legacy,
        stableId,
        name,
        osRoute,
        libraryType: MicroAppLibraryType.Window,
        windowLibraryName: windowLibraryName as LegacyAppWindowLibraryName,
        containerId,
        bundleUrl,
        permission: null,
        requiredHierarchy,
      }
    }

    return {
      type: MicroAppType.Native,
      stableId,
      name,
      osRoute,
      libraryType,
      windowLibraryName,
      bundleUrl,
      permission: null,
      requiredHierarchy,
    }
  }

  if (noCodeAppType === NoCodeAppType.EmbeddedLink) {
    const embeddedLinkAppInstance = routedAppInstance as AppInstanceEmbeddedLinkShort
    let url = embeddedLinkAppInstance.devhubMetadata.version.config.url

    if (
      embeddedLinkAppInstance.assignmentType === AppInstanceAssignmentType.Workspace &&
      embeddedLinkAppInstance.context.url
    ) {
      url = embeddedLinkAppInstance.context.url
    }

    return {
      type: NoCodeAppType.EmbeddedLink,
      stableId,
      name,
      osRoute,
      url,
    }
  }

  if (noCodeAppType === NoCodeAppType.PageBuilder) {
    const pageBuilderAppInstance = routedAppInstance as AppInstancePageBuilderShort
    const externalConfigId = pageBuilderAppInstance.devhubMetadata.version.externalConfigId!

    return {
      type: NoCodeAppType.PageBuilder,
      stableId,
      name,
      osRoute,
      pageId: '',
      externalConfigId,
    }
  }

  if (noCodeAppType === NoCodeAppType.EmbeddedCode) {
    const embeddedCodeAppInstance = routedAppInstance as AppInstanceEmbeddedCodeShort
    let embeddedCode = embeddedCodeAppInstance.devhubMetadata.version.config.embeddedCode

    if (
      embeddedCodeAppInstance.assignmentType === AppInstanceAssignmentType.Workspace &&
      embeddedCodeAppInstance.context.embeddedCode
    ) {
      embeddedCode = embeddedCodeAppInstance.context.embeddedCode
    }

    return {
      type: NoCodeAppType.EmbeddedCode,
      stableId,
      name,
      osRoute,
      embeddedCode,
    }
  }

  if (noCodeAppType === NoCodeAppType.MiroBoard) {
    const miroAppInstance = routedAppInstance as AppInstanceMiroShort
    let url: MayBeNull<string> = null

    if (miroAppInstance.assignmentType === AppInstanceAssignmentType.Workspace && miroAppInstance.context.url) {
      url = miroAppInstance.context.url
    }

    return {
      type: NoCodeAppType.MiroBoard,
      stableId,
      name,
      osRoute,
      url,
    }
  }

  if (noCodeAppType === NoCodeAppType.CodeUpload) {
    const codeUploadAppInstance = routedAppInstance as AppInstanceCodeUploadShort
    const homePageUrl = codeUploadAppInstance.devhubMetadata.version.config.homePageUrl

    return {
      type: NoCodeAppType.CodeUpload,
      stableId,
      name,
      osRoute,
      url: homePageUrl,
    }
  }

  throw new Error('Unsupported App Instance type')
}

export const isValidHierarchyLevel = ({
  app,
  workspaceAzId,
  mapping,
}: {
  app: MayBeNull<AppInstanceApp>
  workspaceAzId: MayBeNull<string>
  mapping: NavigationTreeMapping
}) => {
  if (!app || isNoCodeAppInstanceApp(app)) {
    return true
  }

  return isRequiredHierarchyLevel({
    workspaceAzId,
    requiredHierarchy: app.requiredHierarchy,
    mapping,
  })
}
