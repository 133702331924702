import { MayBeNull } from '@wpp-open/core'

import { AppInstanceFull } from 'types/apps/appInstances'
import {
  LocalLegacyMicroAppConfig,
  LegacyMicroAppConfig,
  LocalMicroAppConfig,
  NativeMicroAppConfig,
  SystemMicroAppConfig,
} from 'types/apps/microApps'
import { RenderableNoCodeApp } from 'types/apps/noCodeApps'
import {
  ProjectCanvasApplicationShort,
  ProjectCanvasFluidShort,
  ProjectCanvasLinearShort,
  ProjectPhaseShort,
  ProjectShort,
} from 'types/projects/projectNavigation'

export enum AppDataType {
  ExternalMicroApp = 'ExternalMicroApp',
  ExternalLegacyMicroApp = 'ExternalLegacyMicroApp',
  SystemMicroApp = 'SystemMicroApp',
  LocalMicroApp = 'LocalMicroApp',
  LocalLegacyMicroApp = 'LocalLegacyMicroApp',
  NoCodeApp = 'NoCodeApp',
  Invalid = 'Invalid',
  Loading = 'Loading',
}

export type LegacyMicroAppData = ExternalLegacyMicroAppData | LocalLegacyMicroAppData
export type NativeMicroAppData = ExternalMicroAppData | SystemMicroAppData | LocalMicroAppData
export type MicroAppData = LegacyMicroAppData | NativeMicroAppData

export type AppData = MicroAppData | NoCodeAppData | InvalidAppData | LoadingAppData

interface ContextData {
  currentBaseUrl: string
  activeWorkspaceId: MayBeNull<string>
  appInstance: MayBeNull<AppInstanceFull>
  project: MayBeNull<ProjectShort>
  projectCanvas: MayBeNull<ProjectCanvasLinearShort | ProjectCanvasFluidShort>
  projectPhase: MayBeNull<ProjectPhaseShort>
  projectItem: MayBeNull<ProjectCanvasApplicationShort>
  appCustomConfig: MayBeNull<Record<string, any>>
  additionalContext: MayBeNull<Record<string, any>>
  isCustomNavigation: boolean
}

export interface ExternalMicroAppData extends ContextData {
  type: AppDataType.ExternalMicroApp
  app: NativeMicroAppConfig
}

export interface ExternalLegacyMicroAppData extends ContextData {
  type: AppDataType.ExternalLegacyMicroApp
  app: LegacyMicroAppConfig
}

export interface SystemMicroAppData extends ContextData {
  type: AppDataType.SystemMicroApp
  app: SystemMicroAppConfig

  activeWorkspaceId: null
  appInstance: null
  project: null
  projectCanvas: null
  projectPhase: null
  projectItem: null
  isCustomNavigation: false
}

export interface LocalMicroAppData extends ContextData {
  type: AppDataType.LocalMicroApp
  app: LocalMicroAppConfig

  appInstance: null
  project: null
  projectCanvas: null
  projectPhase: null
  projectItem: null
  isCustomNavigation: false
}

export interface LocalLegacyMicroAppData extends ContextData {
  type: AppDataType.LocalLegacyMicroApp
  app: LocalLegacyMicroAppConfig

  appInstance: null
  project: null
  projectCanvas: null
  projectPhase: null
  projectItem: null
  isCustomNavigation: false
}

export interface NoCodeAppData extends ContextData {
  type: AppDataType.NoCodeApp
  app: RenderableNoCodeApp
}

export interface InvalidAppData extends ContextData {
  type: AppDataType.Invalid
  app: null

  currentBaseUrl: ''
  activeWorkspaceId: null
  appInstance: null
  project: null
  projectCanvas: null
  projectPhase: null
  projectItem: null
  isCustomNavigation: false
}

// Provided when data from url is being validated
export interface LoadingAppData extends ContextData {
  type: AppDataType.Loading
  app: null

  activeWorkspaceId: null
  appInstance: null
  project: null
  projectCanvas: null
  projectPhase: null
  projectItem: null
  isCustomNavigation: false
}
