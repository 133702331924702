import { MayBeNull } from '@wpp-open/core'
import { isBefore, isEqual, setDefaultOptions } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'

// Make it dynamic after locale change functionality enabled in profile
export const dateLocale = enGB

export const setDateLocale = () => {
  setDefaultOptions({ locale: enGB })
}

export const getCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * cons tz = 'Europe/Bucharest'
 * formatTimeZoneToLabel(tz) // 'Bucharest, Europe'
 */
export const formatTimeZoneToLabel = (timeZone: string) => timeZone.replace(/(\w+)\/(\w+)/, '$2, $1')

export const getCurrentTimeZoneLabel = () => formatTimeZoneToLabel(getCurrentTimeZone())

export const isDate = (value: unknown): value is Date => value instanceof Date

export const isValidDate = (value: Date) => !isNaN(value.getTime())

export const isTimeOptionDisabled = (date: MayBeNull<Date>, dateToCompare = new Date()) => {
  if (!date) {
    return false
  }

  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare)
}
