import { DefaultChildHierarchyLevelType, DefaultHierarchyLevelType } from '@wpp-open/core'

import { HeaderAppTag } from 'layout/header/utils'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'

export const WIDGET_FIELD_ID = {
  EMAIL: 'email',
  NAME: 'name',
  ISSUE: '4626858848156',
  ARCHITECT_MODULE: '1900003906494',
  TICKET_TYPE: '360025007920',
  REQUEST_TYPE: '4648005540636',
  SUBJECT: 'subject',
  DESCRIPTION: 'description',
}

export const ISSUE_FIELD_VALUE = {
  OS: 'os',
  ARCHITECT: 'maximize',
  AUDIENCE_TRANSLATOR: 'audience_translator',
  AUDIENCE_ORIGIN: 'audience_origin',
  AUDIENCE_EXPLORER: 'audience_explorer',
  MARKETPLACE: 'marketplace',
  PROTEUS_ONBOARDING: 'os_proteus',
  TICKET_TYPE_REQUEST: 'support_request',
  REQUEST_TYPE_ADVERTISER: 'creating_an_advertiser',
  REQUEST_TYPE_BRAND: 'adding_brands',
  REQUEST_TYPE_MARKET: 'adding_markets',
}

export const TICKET_TYPE_FIELD_VALUE = {
  SUPPORT: 'support_issues',
}

export const WIDGET_PAGE_CODES = {
  LOGIN: 'wpp-os-login-page',
  OS_INTERNAL_PAGE: 'wpp-os-internal-page',
} as const

export const HELP_LINKS_BY_APP_TAG: Partial<
  Record<HeaderAppTag, { communityUrl?: string; knowledgeBaseUrl?: string }>
> = {
  [LegacyAppWindowLibraryName.AudienceBuilder]: {
    communityUrl: 'https://architect-community.choreograph.com/hc/en-gb/community/topics',
    knowledgeBaseUrl: 'https://chos-help.choreograph.com/hc/en-us/sections/4416944428818-Audiences',
  },
  [LegacyAppWindowLibraryName.AudiencesExplorer]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/audience-explorer.aspx',
  },
  [LegacyAppWindowLibraryName.AudienceComposer]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/audience-explorer-synthetic.aspx',
  },
  [LegacyAppWindowLibraryName.AudienceTranslator]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/audience-translator.aspx',
  },
  [LegacyAppWindowLibraryName.Audiences]: {
    communityUrl:
      'https://teams.microsoft.com/l/channel/19%3aCdy7SIB_SrNUIaGuZ0K6McRoR_5s_vhKTPcm4db4tMY1%40thread.tacv2/General?groupId=be062c00-689e-4517-abf8-0c74e15c365c&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3',
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/introduction-to-the-audiences-module.aspx',
  },
  [LegacyAppWindowLibraryName.Touchpoints]: {
    communityUrl:
      'https://teams.microsoft.com/l/channel/19%3aCdy7SIB_SrNUIaGuZ0K6McRoR_5s_vhKTPcm4db4tMY1%40thread.tacv2/General?groupId=be062c00-689e-4517-abf8-0c74e15c365c&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3',
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/introduction-to-the-touchpoints-module.aspx',
  },
  [LegacyAppWindowLibraryName.CampaignsCreate]: {
    communityUrl:
      'https://teams.microsoft.com/l/channel/19%3aCdy7SIB_SrNUIaGuZ0K6McRoR_5s_vhKTPcm4db4tMY1%40thread.tacv2/General?groupId=be062c00-689e-4517-abf8-0c74e15c365c&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3',
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/creating-a-campaign.aspx',
  },
  [LegacyAppWindowLibraryName.CampaignsList]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/Introduction-to-the-Campaigns-Module.aspx',
  },
  [LegacyAppWindowLibraryName.AudienceDB]: {
    knowledgeBaseUrl: 'https://chos-help.choreograph.com/hc/en-us/categories/5274719719964-Audience-Explorer',
  },
  [LegacyAppWindowLibraryName.MediaPlansComparison]: {
    communityUrl:
      'https://teams.microsoft.com/l/channel/19%3aCdy7SIB_SrNUIaGuZ0K6McRoR_5s_vhKTPcm4db4tMY1%40thread.tacv2/General?groupId=be062c00-689e-4517-abf8-0c74e15c365c&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3',
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/Media-Plan-Comparisons.aspx',
  },
  [LegacyAppWindowLibraryName.MediaPlansUploader]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/Introduction-to-the-Custom-Plan-Uploader.aspx',
  },
  [LegacyAppWindowLibraryName.ProteusOnboarding]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/new-seat-onboarding-process.aspx',
  },
  [LegacyAppWindowLibraryName.ArchitectJunior]: {
    knowledgeBaseUrl:
      'https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/introduction-to-Express-Scenario.aspx',
  },
}

export const CONTACT_SUPPORT_LABEL = 'Contact Support'

export const ARCHITECT_APPS: LegacyAppWindowLibraryName[] = [
  LegacyAppWindowLibraryName.Audiences,
  LegacyAppWindowLibraryName.AudienceDB,
  LegacyAppWindowLibraryName.Touchpoints,
  LegacyAppWindowLibraryName.CampaignsCreate,
  LegacyAppWindowLibraryName.MediaPlanner,
  LegacyAppWindowLibraryName.MediaPlansComparison,
  LegacyAppWindowLibraryName.ProteusOnboarding,
  // LegacyAppWindowLibraryName.ArchitectJunior,
  // LegacyAppWindowLibraryName.AudienceTranslator,
  // LegacyAppWindowLibraryName.AudienceLibrary,
  // LegacyAppWindowLibraryName.AudiencesViewer,
]

export const ARCHITECT_MODULES: Partial<Record<LegacyAppWindowLibraryName, string>> = {
  [LegacyAppWindowLibraryName.CampaignsCreate]: 'campaigns',
  [LegacyAppWindowLibraryName.MediaPlansComparison]: 'campaigns',
  [LegacyAppWindowLibraryName.MediaPlanner]: 'campaigns',
  [LegacyAppWindowLibraryName.Audiences]: 'audiences',
  [LegacyAppWindowLibraryName.AudienceDB]: 'audiences',
  [LegacyAppWindowLibraryName.Touchpoints]: 'touchpoints',
  // [LegacyAppWindowLibraryName.AudiencesViewer]: 'audiences',
  // [LegacyAppWindowLibraryName.AudienceTranslator]: 'audiences',
  // [LegacyAppWindowLibraryName.AudienceLibrary]: 'audiences',
}

export const requestNewEntityPrefill: Record<
  DefaultChildHierarchyLevelType,
  { ticketType: string; requestType: string; subject: string; description: string }
> = {
  [DefaultHierarchyLevelType.Client]: {
    ticketType: ISSUE_FIELD_VALUE.TICKET_TYPE_REQUEST,
    requestType: ISSUE_FIELD_VALUE.REQUEST_TYPE_ADVERTISER,
    subject: '[Master Data] Request to add Client/Advertiser to Master Data',
    description:
      'Please specify name of Client/Advertiser to be added (REQUIRED):\nPlease upload item related logo below (optional)',
  },
  [DefaultHierarchyLevelType.Brand]: {
    ticketType: ISSUE_FIELD_VALUE.TICKET_TYPE_REQUEST,
    requestType: ISSUE_FIELD_VALUE.REQUEST_TYPE_BRAND,
    subject: '[Master Data] Request to add Brand to Master Data',
    description:
      'Please specify name of Brand to be added (REQUIRED):\nPlease upload item related logo below (optional)',
  },
  [DefaultHierarchyLevelType.Market]: {
    ticketType: ISSUE_FIELD_VALUE.TICKET_TYPE_REQUEST,
    requestType: ISSUE_FIELD_VALUE.REQUEST_TYPE_MARKET,
    subject: '[Master Data] Request to add Market to Master Data',
    description: 'Please specify name of Market to be added (REQUIRED):',
  },
}
