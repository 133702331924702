import { MicroAppContext, MicroAppContextListener, MicroAppCustomProps } from '@wpp-open/core'
import PubSub from 'pubsub-js'
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NOT_MOUNTED, SingleSpaCustomEventDetail } from 'single-spa'

import { useAuth } from 'providers/auth/AuthContext'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { MicroAppsContext } from 'providers/microApps/MicroAppsContext'
import { resolveContextHierarchy, useOpenApp, useTriggerError } from 'providers/microApps/utils'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { MicroAppConfig } from 'types/apps/microApps'
import { trackAnalytics } from 'utils/analytics'
import { unregisterAllApps } from 'utils/singleSpa'

export const MicroAppsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isMicroAppActive, setIsMicroAppActive] = useState(false)
  const { appData, openNavigation } = useOsState()
  const openApp = useOpenApp()
  const triggerError = useTriggerError()
  const { jwt } = useAuth()
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const subscribersRef = useRef(new Set<string>())
  const { activeWorkspaceId, currentBaseUrl, project, projectItem, appCustomConfig, additionalContext } = appData

  const tokenRef = useRef(jwt)
  tokenRef.current = jwt

  const contextHierarchy = useMemo(
    () => resolveContextHierarchy({ activeWorkspaceId, navigationTree: navigationTreeWithHiddenLevel }),
    [activeWorkspaceId, navigationTreeWithHiddenLevel],
  )

  const microAppsContextValue = useMemo<MicroAppContext>(() => {
    const projectData: MicroAppContext['project'] =
      project && projectItem
        ? {
            id: project.id,
            name: project.name,
            type: project.type,
            itemId: String(projectItem.id),
            itemCompleteState: projectItem.task?.status ?? null,
          }
        : null

    return {
      baseUrl: currentBaseUrl,
      workspace: contextHierarchy,
      project: projectData,
      permissions,
      tenant: currentTenant,
      navigationTree: navigationTreeWithHiddenLevel,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
      appCustomConfig,
      additional: additionalContext,
    }
  }, [
    additionalContext,
    appCustomConfig,
    contextHierarchy,
    currentBaseUrl,
    currentTaxonomy,
    currentTenant,
    navigationTreeWithHiddenLevel,
    permissions,
    project,
    projectItem,
    resolvedTheme,
    userDetails,
  ])

  const microAppsContextValueRef = useRef(microAppsContextValue)
  microAppsContextValueRef.current = microAppsContextValue

  useEffect(() => {
    subscribersRef.current.forEach(appId => {
      const safeContextValue = structuredClone(microAppsContextValue)

      PubSub.publish(appId, safeContextValue)
    })
  }, [microAppsContextValue])

  useEffect(() => {
    const handler = ({ detail }: CustomEvent<SingleSpaCustomEventDetail>) => {
      detail.appsByNewStatus[NOT_MOUNTED].forEach(appId => {
        PubSub.unsubscribe(appId)
        subscribersRef.current.delete(appId)
      })
    }

    window.addEventListener('single-spa:before-app-change', handler)

    const subscriberNames = subscribersRef.current

    // Cleanup is called only when we enter public routes
    return () => {
      subscriberNames.forEach(appId => {
        PubSub.unsubscribe(appId)
      })

      window.removeEventListener('single-spa:before-app-change', handler)

      // As we cancel app subscriptions, we also unregister the apps
      unregisterAllApps()
    }
  }, [])

  const handleSubscribeToAppState = useCallback((appId: string, listener: MicroAppContextListener) => {
    const subscriberToken = PubSub.subscribe(appId, (msg, value) => {
      listener(value)
    })
    const safeContextValue = structuredClone(microAppsContextValueRef.current)

    PubSub.publish(appId, safeContextValue)
    subscribersRef.current.add(appId)

    return () => {
      PubSub.unsubscribe(subscriberToken)

      const wasLast = PubSub.countSubscriptions(appId) === 0

      if (wasLast) {
        subscribersRef.current.delete(appId)
      }
    }
  }, [])

  const handleUnsubscribeFromAppState = useCallback(({ stableId }: Pick<MicroAppConfig, 'stableId'>) => {
    PubSub.unsubscribe(stableId)
    subscribersRef.current.delete(stableId)
  }, [])

  const getAccessToken = useCallback(() => tokenRef.current, [])

  const getMicroAppCustomProps = useCallback(
    ({ stableId }: Pick<MicroAppConfig, 'stableId'>): MicroAppCustomProps => {
      return {
        domElementGetter: () => document.getElementById('micro-app')!,
        osApi: {
          getAccessToken,
          analytics: {
            track: trackAnalytics,
          },
          navigation: {
            openMenu: openNavigation,
            openApp,
            triggerError,
          },
        },
        osContext: {
          subscribe: listener => handleSubscribeToAppState(stableId, listener),
        },
      }
    },
    [getAccessToken, handleSubscribeToAppState, openApp, openNavigation, triggerError],
  )

  return (
    <MicroAppsContext.Provider
      value={{
        isMicroAppActive,
        setIsMicroAppActive,
        getMicroAppCustomProps,
        unsubscribeApp: handleUnsubscribeFromAppState,
      }}
    >
      {children}
    </MicroAppsContext.Provider>
  )
}
