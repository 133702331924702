import { ThemeStyles } from '@platform-ui-kit/components-library'

import { AnalyticsAction } from './analytics'
import { MayBeNull } from './common'
import { NavigationTree } from './mapping'
import { PermissionsRecord } from './permissions'
import { SelectedProjectData } from './project'
import { Taxonomy } from './taxonomy'
import { Tenant } from './tenant'
import { UserDetails } from './user'
import { SelectedWorkspace } from './workspace'

export type OpenMenuHandler = (preselectedNodeAzId?: MayBeNull<string>) => void
export type OpenAppHandler = (appInstanceId: string) => void

export enum AppErrorType {
  ForbiddenPage = 'Forbidden page',
  CriticalError = 'Critical error',
}

export type TriggerErrorHandler = (errorType: AppErrorType) => void

export interface MicroAppContext {
  /**
   * A url path without a leading slash.
   * Example: "application/123/tags-manager"
   */
  baseUrl: string

  tenant: Tenant
  workspace: MayBeNull<SelectedWorkspace>
  project: MayBeNull<SelectedProjectData>
  permissions: PermissionsRecord[]
  userDetails: UserDetails
  theme: ThemeStyles
  navigationTree: NavigationTree
  taxonomy: Taxonomy
  appCustomConfig: MayBeNull<Record<string, any>>
  additional: MayBeNull<Record<string, any>>
}

export type MicroAppContextListener = (context: MicroAppContext) => void

export interface MicroAppCustomProps {
  /**
   * Used by single-spa
   */
  domElementGetter: () => HTMLElement

  osApi: {
    getAccessToken: () => string
    navigation: {
      openMenu: OpenMenuHandler
      openApp: OpenAppHandler
      triggerError: TriggerErrorHandler
    }
    analytics: {
      track: (data: AnalyticsAction) => void
    }
  }
  osContext: {
    subscribe: (listener: MicroAppContextListener) => () => void
  }
}

export interface FramedMicroAppMethods {
  receiveOsContext: MicroAppContextListener
}

export interface FramedMicroAppParentMethods {
  osApi: MicroAppCustomProps['osApi']
}
