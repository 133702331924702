import { WppTypography } from '@platform-ui-kit/components-library-react'

import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import { useGetDevHubLogoUrl } from 'components/hierarchyAppsManagement/utils'
import styles from 'components/navigationMenu/navigationMenuContent/appGroupAppsNavigationMenuColumn/AppGroupAppsNavigationMenuColumn.module.scss'
import { AppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/appMenuItem/AppNavigationMenuItem'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { useGroupAppInstances } from 'components/treeList/appsTreeList/utils'
import { AppInstanceShort } from 'types/apps/appInstances'

interface Props {
  appInstances: AppInstanceShort[]
}

export const AppGroupAppsNavigationMenuColumn = ({ appInstances }: Props) => {
  const getDevHubLogoUrl = useGetDevHubLogoUrl(appInstances)
  const visibleAppInstances = appInstances.filter(app => app.flags.visible)

  const { grouped } = useGroupAppInstances({ appInstances: visibleAppInstances })

  const { appInstances: groupAppInstances, group, subgroups } = grouped[0]

  return (
    <NavigationMenuTreeListColumn data-testid="apps-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <Flex direction="column">
          <WppTypography type="l-strong" data-testid="apps-navigation-menu-column-title">
            {group.name}
          </WppTypography>

          {!!group.description && (
            <WppTypography
              className={styles.groupDescription}
              type="xs-body"
              title={group.description}
              data-testid="apps-navigation-menu-column-description"
            >
              {group.description}
            </WppTypography>
          )}
        </Flex>
      </NavigationMenuColumnHeader>

      <Flex as={FadeIn} direction="column" gap={2}>
        {!!subgroups.length &&
          subgroups.map(({ subgroup, appInstances }) => (
            <Flex direction="column" key={subgroup.id} className={styles.subGroups}>
              <WppTypography type="s-strong" data-testid="subgroup-header">
                {subgroup.name}
              </WppTypography>

              {!!subgroup.description && (
                <WppTypography type="xs-body" title={subgroup.description} data-testid="subgroup-subheader">
                  {subgroup.description}
                </WppTypography>
              )}

              {appInstances.map(appInstance => (
                <AppNavigationMenuItem
                  key={appInstance.id}
                  appInstance={appInstance}
                  logoUrl={
                    appInstance.logoThumbnail?.url ||
                    getDevHubLogoUrl(appInstance.devhubMetadata.logoMeta?.thumbnail.key)
                  }
                />
              ))}
            </Flex>
          ))}
        {groupAppInstances.map(appInstance => (
          <AppNavigationMenuItem
            key={appInstance.id}
            appInstance={appInstance}
            logoUrl={
              appInstance.logoThumbnail?.url || getDevHubLogoUrl(appInstance.devhubMetadata.logoMeta?.thumbnail.key)
            }
          />
        ))}
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}
